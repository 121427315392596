<owc-modal-dialog [(ngModel)]="visible" size="lg">
  <div slot="header" class="dialog-title">
    <span i18n="BreakpointPopup.Title@@break-point-details">BREAK POINT DETAILS</span>
  </div>
  <div class="breakpoint-list">
    <owc-typography
      variant="body1"
      i18n="BreakpointPopup.Notes(headerPageTitle)@@this-content-is-from-an-uploaded-file"
    >
      This content is from an uploaded file
    </owc-typography>

    <owc-table height="100%" class="mates-table">
      <owc-table-header>
        <owc-table-header-cell width="35%" i18n="BreakpointPopup.HeaderCell@@mates">
          Mates
        </owc-table-header-cell>
        <owc-table-header-cell
          width="25%"
          i18n="BreakpointPopup.HeaderCell@@supporting-reads-count"
        >
          Supporting reads count
        </owc-table-header-cell>
        <owc-table-header-cell
          width="25%"
          i18n="BreakpointPopup.HeaderCell@@supporting-reads-percentage"
        >
          Supporting reads percentage
        </owc-table-header-cell>
        <owc-table-header-cell width="15%" i18n="BreakpointPopup.HeaderCell@@quality-score">
          Quality score
        </owc-table-header-cell>
      </owc-table-header>
      <owc-table-body>
        <owc-table-row *ngFor="let row of data; index as index">
          <owc-table-cell>
            <app-fusion-partner-name [breakPoints]="row.breakPoints"></app-fusion-partner-name>
          </owc-table-cell>
          <owc-table-cell>{{ row.supportingReadsCount | localeNumber: '0,0' }}</owc-table-cell>
          <owc-table-cell>
            {{ row.supportingReadsFraction * 100 | localeNumber: '0.00' }}
          </owc-table-cell>
          <owc-table-cell>
            {{ row.qualityScore | localeNumber: '0,0.000000' }}
          </owc-table-cell>
        </owc-table-row>
      </owc-table-body>
    </owc-table>
  </div>
  <div slot="actions" class="actions">
    <owc-button variant="primary" (click)="close()" i18n="BreakpointPopup.Button@@close">
      Close
    </owc-button>
  </div>
</owc-modal-dialog>
