import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-case-event-notification',
  templateUrl: './case-event-notification.component.html',
  styleUrls: ['./case-event-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseEventNotificationComponent {
  @Input() count: number;
  @Input() hasError = false;
  @Input() smallSize = false;
  @Input() rowId: string;
  public readonly COUNT_THRESHOLD = 99;
}
