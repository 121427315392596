<owc-input class="full-width" [label]="placeholder" [formControl]="dateControl" [error]="dateControl.invalid && dateControl.touched"  (blur)="onBlur($event)" maxlength="10" spellcheck="false" appNoAutoComplete>
  <div slot="assistive-text" *ngIf="!(dateControl.invalid && dateControl.touched)" class="secondary-text">{{formatDisplay}}</div>
  <div slot="prefix">
    <owc-icon name="date" family="outlined"></owc-icon>
  </div>
  <div slot="error-text" *ngIf="dateControl.invalid && dateControl.touched">
    <span *ngIf="dateControl.hasError('pattern')">
      <ng-container i18n="Enter the number in specified format only|Error message for date format validation@@error-date-format">Enter numbers in the form</ng-container> {{formatDisplay}}
    </span>
    <span *ngIf="dateControl.hasError('invalidDate')"
              i18n="Date is invalid|Error message which will appear if user enter's invalid date, for ex: 02/31/2017@@error-invalid-date">Invalid date</span>
    <span *ngIf="dateControl.hasError('futureDateValidation')"
              i18n="Date should be today or less|Error message indicating future date is not allowed@@error-future-date">Must be in the past</span>
    <span *ngIf="dateControl.hasError('pastDateValidation')"
              i18n="Date should be today or greater|Error message indicating past date is not allowed@@error-past-date">Must be in the future</span>
    <span *ngIf="dateControl.hasError('required')"
              i18n="Validation for required field|Message to be displayed as a validation@@required-field-validation">This field is required</span>
  </div>
</owc-input>
