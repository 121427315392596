<owc-icon-button
  *ngIf="!hideDefaultIconButton"
  [type]="type"
  flat
  [icon]="icon"
  [id]="customId ? (customId + '-' + index) : ('owc-menu-' + index)"
  (click)="$event.stopPropagation(); menuVisible = !menuVisible"
></owc-icon-button>
<owc-menu
  [anchor]="customId ? (customId + '-' + index) : ('owc-menu-' + index)"
  [(ngModel)]="menuVisible"
  (clickOutside)="menuVisible = false"
>
  <!-- Using content projection to display menu items -->
  <ng-content></ng-content>
</owc-menu>
