export interface IPAddressRange {
  start: string;
  end?: string;
}

export enum IPAddressRangeType {
  single = 'single',
  range = 'range'
}

export interface APIMetaData {
  createCaseEnabled?: boolean;
  getCaseResultsEnabled?: boolean;
  allowedAddresses?: IPAddressRange[];
}

export interface APIMetaDataReponse extends APIMetaData {
  existingKey: boolean;
  _links?: any;
}

export interface APIKeyResponse {
  apiKey: string;
}
