import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ProfessionalServicesService } from 'app/services/professional-services.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProServiceAccessGuard implements CanActivate {
  constructor(
    private professionalServicesService: ProfessionalServicesService,
    private router: Router
  ) {}
  canActivate(): Observable<boolean> {
    return this.professionalServicesService.isProfessionalServicesEnabledForTenant$.pipe(
      map((isProServEnabled) => {
        if (!isProServEnabled) {
          this.router.navigate(['forbidden']);
          return false;
        }
        return true;
      })
    );
  }
}
