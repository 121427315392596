import {
  of,
  forkJoin,
  defer,
  Observable,
  BehaviorSubject,
  Subject,
  timer,
  Subscription
} from 'rxjs';
import { catchError, tap, mergeMap, map, startWith, switchMap } from 'rxjs/operators';
import { get as _get, cloneDeep as _cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { LastUpdated } from 'app/model/valueObjects/lastUpdatedEnum';
import { CaseData } from 'app/model/entities/CaseList';
import { CommonService, HEADER_ETAG, HEADER_IF_NONE_MATCH } from './common.service';
import { AuthService } from './auth.service';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';
import { HomePagePagination, PagerConfig, SORT_ORDER } from 'app/model/entities/pagination';
import { TableSort } from 'app/model/entities/OwcTableSortedDataSource';
import { SORT_BY } from 'app/domain/home/case-list/case-list.component';

export interface AuditMap {
  [caseId: string]: number;
}

export interface VariantMap {
  [caseId: string]: {
    variants: string[];
    variantCount: number;
  };
}

export enum ListName {
  inProgress = 'inProgress',
  toApprove = 'toApprove',
  signed = 'signed',
  canceled = 'canceled'
}

export enum statusGroup {
  inProgress = 'IN_PROGRESS',
  toApprove = 'TO_APPROVE',
  canceled = 'CANCELED',
  signed = 'SIGNED'
}

export const pageSizes = {
  twentyFive: 25,
  fifty: 50
};

export const pageSizesOptions = [pageSizes.twentyFive, pageSizes.fifty];

@Injectable()
export class CaseListService {
  private defaultPageObj = {
    number: 1,
    size: pageSizes.twentyFive,
    totalElements: 0,
    totalPages: 0,
    sortBy: SORT_BY.CREATED_AT,
    sortOrder: SORT_ORDER.DESCENDING,
    nextURL: null,
    previousURL: null,
    lastURL: null,
    firstURL: null,
    currentURL: null
  };

  private defaultCaseObj = {
    list: new BehaviorSubject<CaseData[]>([]),
    etag: <string>null,
    cachedList: <CaseData[]>[],
    updateRequired: false,
    loadError: false,
    lastUpdateTime: <Date>null,
    lastUpdated: new BehaviorSubject<LastUpdated>(LastUpdated.unknown),
    totalNumberOfCases: new BehaviorSubject<number>(0),
    page: new BehaviorSubject<HomePagePagination>(_cloneDeep(this.defaultPageObj))
  };

  private _cases = {
    [ListName.inProgress]: _cloneDeep(this.defaultCaseObj),
    [ListName.toApprove]: _cloneDeep(this.defaultCaseObj),
    [ListName.signed]: _cloneDeep(this.defaultCaseObj),
    [ListName.canceled]: _cloneDeep(this.defaultCaseObj)
  };

  private _auditSummary = {
    [ListName.inProgress]: {
      etag: <string>null,
      cachedMap: <AuditMap>{}
    },
    [ListName.toApprove]: {
      etag: <string>null,
      cachedMap: <AuditMap>{}
    },
    [ListName.signed]: {
      etag: <string>null,
      cachedMap: <AuditMap>{}
    },
    [ListName.canceled]: {
      etag: <string>null,
      cachedMap: <AuditMap>{}
    }
  };

  private _variantSummary = {
    [ListName.inProgress]: {
      etag: <string>null,
      cachedMap: <VariantMap>{}
    },
    [ListName.toApprove]: {
      etag: <string>null,
      cachedMap: <VariantMap>{}
    },
    [ListName.signed]: {
      etag: <string>null,
      cachedMap: <VariantMap>{}
    },
    [ListName.canceled]: {
      etag: <string>null,
      cachedMap: <VariantMap>{}
    }
  };
  private readonly _pollInterval: number = 10000;
  private _loadingInitial = new BehaviorSubject<boolean>(false);
  private ngUnsubscribe = new Subject<void>();
  private _caseListTabChangeEvent$ = new BehaviorSubject<ListName>(ListName.inProgress);
  private pollingTimer$ = this._caseListTabChangeEvent$.pipe(
    startWith(0), // On load call the function right away
    switchMap(() => timer(0, this._pollInterval)) // Start a new timer that emits every 10 seconds,
  );
  private pollingTimerSubscription: Subscription;

  constructor(private http: HttpClient, private authService: AuthService) {}

  get pagerConfig$(): Observable<PagerConfig> {
    return this._cases[this.activeList].page.asObservable().pipe(
      map((page) => ({
        page: page.number,
        rowsPerPage: page.size,
        total: page.totalPages
      }))
    );
  }

  get activePageSortConfig$(): Observable<TableSort> {
    return this._cases[this.activeList].page.asObservable().pipe(
      map((page) => ({
        column: page.sortBy,
        order: page.sortOrder
      }))
    );
  }

  get pollingTimerGetter$(): Observable<number> {
    return this.pollingTimer$;
  }

  get activeList(): ListName {
    return this._caseListTabChangeEvent$.value;
  }

  set activeListSetter$(selectedTab: ListName) {
    this._loadingInitial.next(true);
    this._caseListTabChangeEvent$.next(selectedTab);
  }

  get casesInProgress$(): Observable<CaseData[]> {
    return this._cases[ListName.inProgress].list.asObservable();
  }

  get casesToApprove$(): Observable<CaseData[]> {
    return this._cases[ListName.toApprove].list.asObservable();
  }

  get casesSigned$(): Observable<CaseData[]> {
    return this._cases[ListName.signed].list.asObservable();
  }

  get casesCanceled$(): Observable<CaseData[]> {
    return this._cases[ListName.canceled].list.asObservable();
  }

  get lastUpdatedInProgress$(): Observable<LastUpdated> {
    return this._cases[ListName.inProgress].lastUpdated.asObservable();
  }

  get lastUpdatedToApprove$(): Observable<LastUpdated> {
    return this._cases[ListName.toApprove].lastUpdated.asObservable();
  }

  get lastUpdatedCanceled$(): Observable<LastUpdated> {
    return this._cases[ListName.canceled].lastUpdated.asObservable();
  }

  get lastUpdatedSigned$(): Observable<LastUpdated> {
    return this._cases[ListName.signed].lastUpdated.asObservable();
  }

  get loadingInitial$(): Observable<boolean> {
    return this._loadingInitial.asObservable();
  }

  private resetPollingFlags(): void {
    this._cases[this.activeList].updateRequired = false;
    this._cases[this.activeList].loadError = false;
  }

  getTotalNumberOfCases$(listName: ListName): Observable<number> {
    return this._cases[listName].totalNumberOfCases.asObservable();
  }

  updatePagination(pageDetails: PagerConfig): void {
    const { rowsPerPage, page } = pageDetails;
    const { size, number } = this._cases[this.activeList].page.getValue();

    /**
     * Need to add below fix as pageChangeHandler (pagination event) was firing even on the tabs
     * change event. This will return if there are no changes in rowsPerPage and page numbers as
     * well as when the user changes the tabs, it fires with rowsPerPage === 1.
     */
    if ((rowsPerPage === size && page === number) || rowsPerPage === 1) {
      return;
    }

    const updatedPage = {
      ...this._cases[this.activeList].page.getValue(),
      size: pageDetails.rowsPerPage,
      number: pageDetails.page
    };

    this._cases[this.activeList].page.next(updatedPage);
    this.activeListSetter$ = this.activeList;
  }

  updateSorting(sortByEntity: SORT_BY, sortDirection: SORT_ORDER): void {
    const { sortBy, sortOrder } = this._cases[this.activeList].page.getValue();

    if (sortByEntity === sortBy && sortDirection === sortOrder) {
      return;
    }

    const updatedPage = {
      ...this._cases[this.activeList].page.getValue(),
      number: this.defaultPageObj.number,
      sortBy: sortByEntity,
      sortOrder: sortDirection
    };

    this._cases[this.activeList].page.next(updatedPage);
    this.activeListSetter$ = this.activeList;
  }

  getListParamsForCurrentLink(listName: ListName): HttpParams {
    const { number, size, sortBy, sortOrder } = this._cases[listName].page.getValue();
    // Subtract 1 from the page number to match with BE pagination
    const pageNumber = number - 1;
    return new HttpParams()
      .set('page', pageNumber.toString())
      .set('size', size.toString())
      .set('statusGroup', statusGroup[listName])
      .set('sort', `${sortBy},${sortOrder}`);
  }

  getListParamsToCreateURL(
    listName: ListName,
    pageNumber: number,
    pageSize: number,
    sortBy: SORT_BY,
    sortOrder: SORT_ORDER
  ): HttpParams {
    // Subtract 1 from the page number to match with BE pagination
    const pageNumberUpdated = pageNumber - 1;
    return new HttpParams()
      .set('page', pageNumberUpdated.toString())
      .set('size', pageSize.toString())
      .set('statusGroup', statusGroup[listName])
      .set('sort', `${sortBy},${sortOrder}`);
  }

  refreshCases(): Observable<CaseData[]> {
    return defer(() => {
      this.resetPollingFlags();

      return this.loadList(this.activeList);
    });
  }

  private getListOptions(listName: ListName): any {
    return Object.assign(this.getBaseOptions(this._cases[listName].etag), {
      params: this.getListParamsForCurrentLink(listName)
    });
  }

  private getBaseOptions(etag: string = null) {
    return Object.assign(
      { observe: 'response' as 'body' }, // Return an HttpResponse so that we can parse HTTP headers
      etag ? { headers: new HttpHeaders().set(HEADER_IF_NONE_MATCH, etag) } : {}
    );
  }

  private getEtag(response: HttpResponse<any>): string {
    return response.headers.get(HEADER_ETAG);
  }

  private getURL(): string {
    return this.authService.getURL(SessionLinkKeys.LIST_CASES);
  }

  calculateTotalPages(totalCases: number, casesPerPage: number): number {
    return Math.ceil(totalCases / casesPerPage);
  }

  getActivePage(
    activeListPage: HomePagePagination,
    responsePage: { totalElements: number }
  ): HomePagePagination {
    const totalPages = this.calculateTotalPages(responsePage.totalElements, activeListPage.size);

    return {
      ...activeListPage,
      totalElements: responsePage.totalElements,
      totalPages
    };
  }

  generatePaginationURLs(
    listName: ListName,
    totalCases: number,
    casesPerPage: number,
    currentPage: number
  ): {
    nextURL: string | null;
    previousURL: string | null;
    lastURL: string | null;
    firstURL: string | null;
    currentURL: string | null;
  } {
    const baseURL = this.getURL();
    const totalPages = this.calculateTotalPages(totalCases, casesPerPage);
    const casePage = this._cases[listName].page.getValue();

    let nextURL = null,
      previousURL = null,
      lastURL = null,
      firstURL = null,
      currentURL = null;

    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      const nextPageQueryString = this.getListParamsToCreateURL(
        listName,
        nextPage,
        casesPerPage,
        casePage.sortBy,
        casePage.sortOrder
      ).toString();
      nextURL = `${baseURL}?${nextPageQueryString}`;
    }

    if (currentPage > 1) {
      const previousPage = currentPage - 1;
      const previousPageQueryString = this.getListParamsToCreateURL(
        listName,
        previousPage,
        casesPerPage,
        casePage.sortBy,
        casePage.sortOrder
      ).toString();
      previousURL = `${baseURL}?${previousPageQueryString}`;

      const firstPage = 1;
      const firstPageQueryString = this.getListParamsToCreateURL(
        listName,
        firstPage,
        casesPerPage,
        casePage.sortBy,
        casePage.sortOrder
      ).toString();
      firstURL = `${baseURL}?${firstPageQueryString}`;
    }

    if (totalPages > 0) {
      const lastPage = totalPages;
      const lastPageQueryString = this.getListParamsToCreateURL(
        listName,
        lastPage,
        casesPerPage,
        casePage.sortBy,
        casePage.sortOrder
      ).toString();
      lastURL = `${baseURL}?${lastPageQueryString}`;
    }

    const currentPageQueryString = this.getListParamsToCreateURL(
      listName,
      currentPage,
      casesPerPage,
      casePage.sortBy,
      casePage.sortOrder
    ).toString();
    currentURL = `${baseURL}?${currentPageQueryString}`;

    return { nextURL, previousURL, lastURL, firstURL, currentURL };
  }

  updateTotalNumberOfCases(totalNumberOfCases: {
    [ListName.inProgress]: number;
    [ListName.toApprove]: number;
    [ListName.canceled]: number;
    [ListName.signed]: number;
  }): void {
    if (!totalNumberOfCases) {
      return;
    }

    Object.values(ListName).forEach((listName) => {
      this._cases[listName].totalNumberOfCases.next(totalNumberOfCases[listName]);
    });
  }

  // Load case list data
  private loadList(listName: ListName): Observable<CaseData[]> {
    return this.http.get<any>(this.getURL(), this.getListOptions(listName)).pipe(
      tap<HttpResponse<any>>((response) => {
        this._cases[listName].etag = this.getEtag(response);
        this._cases[listName].updateRequired = true;
        this.updateTotalNumberOfCases(response.body.totalNumberOfCases);

        const responsePage = this.getActivePage(
          this._cases[listName].page.getValue(),
          response?.body?.page
        );
        const { nextURL, previousURL, lastURL, firstURL, currentURL } = this.generatePaginationURLs(
          listName,
          responsePage.totalElements,
          responsePage.size,
          responsePage.number
        );

        const responsePageWithLinks = {
          ...responsePage,
          nextURL,
          previousURL,
          lastURL,
          firstURL,
          currentURL
        };
        this._cases[listName].page.next(responsePageWithLinks);
      }),
      map((response: any) => response.body._embedded['caseUploadSummaries']),
      tap((caseList: CaseData[]) => {
        this._cases[listName].cachedList = caseList;
      }),
      catchError((err) => {
        if (err.status !== 304) {
          this._cases[listName].loadError = true;
        }

        return this.handleErrorResponse<CaseData[]>(
          err.status,
          this._cases[listName].cachedList,
          []
        );
      })
    );
  }

  private handleErrorResponse<T>(
    errorStatus: number,
    cachedResult: T,
    defaultResult: T
  ): Observable<T> {
    if (errorStatus === 304) {
      return of(cachedResult);
    } else {
      return of(defaultResult);
    }
  }

  private updateAllCaseLists(): void {
    Object.values(ListName).forEach((listName) => {
      this._cases[listName].updateRequired = true;
    });
  }

  private loadAuditSummary(caseIds: string[]): Observable<AuditMap> {
    return this.http
      .post<HttpResponse<AuditMap>>(
        this.authService.getURL(SessionLinkKeys.LIST_AUDIT_CASE_SUMMARY),
        { caseIds },
        this.getBaseOptions(this._auditSummary[this.activeList].etag)
      )
      .pipe(
        tap((response: any) => {
          this._auditSummary[this.activeList].etag = this.getEtag(response);
          this.updateAllCaseLists();
        }),
        map((response: any) => {
          return response.body;
        }),
        tap(
          (auditMap: AuditMap) =>
            (this._auditSummary[this.activeList].cachedMap = {
              ...this._auditSummary[this.activeList].cachedMap,
              ...auditMap
            })
        ),
        catchError((err: AuditMap) =>
          this.handleErrorResponse<AuditMap>(
            err.status,
            this._auditSummary[this.activeList].cachedMap,
            {}
          )
        )
      );
  }

  private loadVariantSummary(caseIds: string[]): Observable<VariantMap> {
    return this.http
      .post<HttpResponse<any>>(
        this.authService.getURL(SessionLinkKeys.LIST_INTERPRETATION_CASE_SUMMARY),
        { caseIds },
        this.getBaseOptions(this._variantSummary[this.activeList].etag)
      )
      .pipe(
        tap((response: any) => {
          this._variantSummary[this.activeList].etag = this.getEtag(response);
          this.updateAllCaseLists();
        }),
        map((response: any) =>
          response.body.caseSummaries.reduce((acc, caseSummary) => {
            // Process the list into a mapping object
            acc[caseSummary.caseId] = {
              variants: caseSummary.variants,
              variantCount: caseSummary.variantCount
            };
            return acc;
          }, {})
        ),
        tap(
          (variantMap) =>
            (this._variantSummary[this.activeList].cachedMap = {
              ...this._variantSummary[this.activeList].cachedMap,
              ...variantMap
            })
        ),
        catchError((err) =>
          this.handleErrorResponse<VariantMap>(
            err.status,
            this._variantSummary[this.activeList].cachedMap,
            {}
          )
        )
      );
  }

  private setLastUpdatedText(): void {
    const timeNow = new Date();

    if (this._cases[this.activeList].loadError) {
      this._cases[this.activeList].lastUpdated.next(
        CommonService.calculateElapsedTime(this._cases[this.activeList].lastUpdateTime)
      );
    } else {
      this._cases[this.activeList].lastUpdateTime = timeNow;
      this._cases[this.activeList].lastUpdated.next(CommonService.calculateElapsedTime(timeNow));
    }
  }

  private resetData(): void {
    Object.values(ListName).forEach((listName) => {
      this._cases[listName].list.next([]);
      this._cases[listName].etag = null;
      this._cases[listName].page.next(this.defaultPageObj);
    });
  }

  // Loads audit and variant summaries and returns augmented case data
  processCaseData(caseDataResponses: CaseData[]): Observable<CaseData[]> {
    const allCaseIds = caseDataResponses.map((caseData) => caseData.id) || [];

    return forkJoin([this.loadAuditSummary(allCaseIds), this.loadVariantSummary(allCaseIds)]).pipe(
      map((summaryResponses: any) => {
        const [auditMap, variantMap] = summaryResponses;

        return caseDataResponses.map((caseData) =>
          Object.assign({}, caseData, {
            eventCount: _get(auditMap, caseData.id, 0),
            variants: _get(variantMap, [caseData.id, 'variants'], []),
            variantCount: _get(variantMap, [caseData.id, 'variantCount'], 0)
          })
        );
      })
    );
  }

  private updateListData(listName: ListName, caseDataArray: CaseData[]): void {
    this._cases[listName].list.next(caseDataArray);
  }

  startPolling(): void {
    // Unsubscribe from the previous timer, if any
    if (this.pollingTimerSubscription) {
      this.pollingTimerSubscription.unsubscribe();
    }
    this._loadingInitial.next(true);

    // Subscribe to the new timer
    this.pollingTimerSubscription = this.pollingTimerGetter$
      .pipe(
        switchMap(() => this.refreshCases()),
        mergeMap((responses: CaseData[]) => this.processCaseData(responses)),
        tap((responses) => {
          if (this._loadingInitial.value) this._loadingInitial.next(false);
          const selectedCaseListTab = [...responses];

          if (this.activeList && this._cases[this.activeList].updateRequired) {
            this.updateListData(this.activeList, selectedCaseListTab);
          }

          this.setLastUpdatedText();
        })
      )
      .subscribe();
  }

  resetPolling(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    // Unsubscribe from the current timer
    if (this.pollingTimerSubscription) {
      this.pollingTimerSubscription.unsubscribe();
      this.pollingTimerSubscription = null;
    }
  }

  stopPolling(): void {
    this.resetPolling();
    this.resetData();
  }

  deleteCase(url: string): Observable<CaseData> {
    return this.http.delete<CaseData>(url);
  }

  cancelCase(url: string): Observable<CaseData> {
    return this.http.put<CaseData>(url, null);
  }
}
