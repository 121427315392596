<span [ngSwitch]="type">
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@fusion-supporting-read-percentage"
    *ngSwitchCase="VariantDisplayFieldType.SUPPORTING_READS_PERCENTAGE"
    >Fusion Supporting Read Percentage</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@fusion-supporting-read-depth"
    *ngSwitchCase="VariantDisplayFieldType.SUPPORTING_READS_COUNT"
    >Fusion Supporting Read Depth</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@title-copy-number"
    *ngSwitchCase="VariantDisplayFieldType.COPY_NUMBER"
    >Copy Number</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@title-fold-change"
    *ngSwitchCase="VariantDisplayFieldType.VENDOR_FOLD_CHANGE"
    >Fold Change</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@title-quality-score"
    *ngSwitchCase="VariantDisplayFieldType.QUALITY_SCORE"
    >Quality Score</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@pvalue"
    *ngSwitchCase="VariantDisplayFieldType.P_VALUE"
    >pValue</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@quality"
    *ngSwitchCase="VariantDisplayFieldType.QUALITY_ENUM"
    >Quality</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@equivocal"
    *ngSwitchCase="VariantDisplayFieldType.EQUIVOCAL"
    >Equivocal</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@inframeness"
    *ngSwitchCase="VariantDisplayFieldType.VENDOR_ANNOTATED_IN_FRAME"
    >Inframeness</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@reading-frame"
    *ngSwitchCase="VariantDisplayFieldType.READING_FRAME"
    >Reading Frame</ng-container
  >
  <ng-container *ngSwitchCase="VariantDisplayFieldType.SUPPORTING_READ_PAIRS">
    <span
      *ngIf="annotatedVariant.isSpliceVariant && !compact; else regularSupportingReads"
      i18n="VariantDisplayFieldType.Notes@@splice-supporting-reads"
    >
      # Splice Supporting Reads
    </span>
    <ng-template #regularSupportingReads>
      <span i18n="VariantDisplayFieldType.Notes@@number-reads"
        matTooltip="Number of Reads"
        i18n-matTooltip="VariantDisplayFieldType.Notes@@number-of-reads">
        # Reads
      </span>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="VariantDisplayFieldType.TOTAL_READ_PAIRS">
    <span i18n="VariantDisplayFieldType.Notes@@total-reads">
      Total Reads
    </span>
  </ng-container>

  <ng-container
    i18n="VariantDisplayFieldType.Notes@@number-fusion-supporting-reads"
    *ngSwitchCase="VariantDisplayFieldType.FUSION_SUPPORTING_READS"
    matTooltip="Number of Fusion Supporting Reads"
    i18n-matTooltip="VariantDisplayFieldType.Notes@@number-of-fusion-supporting-reads"
    >Fusion Supporting Reads </ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@directionality"
    *ngSwitchCase="VariantDisplayFieldType.DIRECTIONALITY"
    matTooltip="Directionality"
    i18n-matTooltip="VariantDisplayFieldType.Notes@@directionality"
    >Directionality </ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@number-of-reference-reads"
    *ngSwitchCase="VariantDisplayFieldType.REFERENCE_READS_FOR_FIVE_PRIME_GENE_AND_THREE_PRIME_GENE"
    matTooltip="# Reference reads (5' gene, 3' gene)"
    i18n-matTooltip="VariantDisplayFieldType.Notes@@number-of-reference-reads"
    ># Reference reads (5' gene, 3' gene) </ng-container
  >
  <ng-container
    *ngSwitchCase="VariantDisplayFieldType.ALLELE_FRACTION_PERCENTAGE"
    matTooltip="Allele Fraction"
    i18n-matTooltip="VariantDisplayFieldType.Notes@@allele-fraction">
      <ng-container i18n="VariantDisplayFieldType.Notes@@af" *ngIf="compact; else fullAFLabel">AF</ng-container>
      <ng-template #fullAFLabel>
        <ng-container i18n="VariantDisplayFieldType.Notes@@allele-fraction">
        Allele Fraction
        </ng-container>
      </ng-template>
  </ng-container >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@variant-class"
    *ngSwitchCase="VariantDisplayFieldType.VARIANT_CLASS"
    >Variant Class</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@ratio"
    *ngSwitchCase="VariantDisplayFieldType.RATIO"
    >Ratio</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@impacted-genes"
    *ngSwitchCase="VariantDisplayFieldType.IMPACTED_GENES"
    >Impacted Gene(s)</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@breakpoint-uncertainty"
    *ngSwitchCase="VariantDisplayFieldType.BREAKPOINT_UNCERTAINTY"
    >Breakpoint Uncertainty</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@impacted-exons"
    *ngSwitchCase="VariantDisplayFieldType.IMPACTED_EXONS"
    >Impacted Exons</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@exon-range"
    *ngSwitchCase="VariantDisplayFieldType.EXON_RANGE"
    >Exon Range</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@exon-junction"
    *ngSwitchCase="VariantDisplayFieldType.EXON_JUNCTION"
    >Exon Junction</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@source"
    *ngSwitchCase="VariantDisplayFieldType.NUCLEIC_ACID_TYPE"
    >Source</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@protein-features"
    *ngSwitchCase="VariantDisplayFieldType.PROTEIN_FEATURES"
    >Protein Features</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@genomic-range"
    *ngSwitchCase="VariantDisplayFieldType.CUSTOM_GENOMIC_RANGE"
    >Genomic Range</ng-container
  >
  <ng-container
    i18n="VariantDisplayFieldType.Notes@@in-strand"
    *ngSwitchCase="VariantDisplayFieldType.IN_STRAND"
    >In Strand</ng-container
  >
  <ng-container *ngSwitchCase="VariantDisplayFieldType.REFERENCE_READS_TRANSCRIPT"
    i18n="VariantDisplayFieldType.Notes@@no-reference-supporting-reads">
    # Reference Supporting Reads
  </ng-container>
</span>
