import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-structural-variant-title',
  template: `
    <span>{{ gene }}</span>
    <span class="word-wrap">
      {{ chromosome }}
      <span *ngIf="showColon()">:</span>
      {{ breakpointStart }}
      <span *ngIf="showDash()">-</span>
      {{ breakpointEnd }}
    </span>
  `,
  preserveWhitespaces: true
})
export class StructuralVariantTitleComponent {
  @Input() gene: string;
  @Input() chromosome: string;
  @Input() breakpointStart: number;
  @Input() breakpointEnd: number;

  constructor() {}

  showColon() {
    return (
      this.chromosome &&
      (this.breakpointStart ||
        this.breakpointEnd ||
        this.breakpointStart === 0 ||
        this.breakpointEnd === 0)
    );
  }

  showDash() {
    return (
      (this.breakpointStart || this.breakpointStart === 0) &&
      (this.breakpointEnd || this.breakpointEnd === 0)
    );
  }
}
