export interface Environment {
  production: boolean;
  logoutTimeMinutes: number;
  platform: boolean; // TODO: Move this to UI - feature flag implementation.
  softwareLegalNumbers: {
    ivdGtinNumber: string;
    ivdRefNumber: string;
    ruoGtinNumber: string;
    ruoRefNumber: string;
  };
  standardAndGuidelinesLink: string;
  proxyApiUrl?: string;
}

export const environmentDefaults: Environment = {
  production: false,
  logoutTimeMinutes: 30,
  platform: false,
  softwareLegalNumbers: {
    ivdGtinNumber: '07613336181331',
    ivdRefNumber: '09097821001',
    ruoGtinNumber: '07613336181386',
    ruoRefNumber: '09309799001'
  },
  standardAndGuidelinesLink: 'https://www.jmdjournal.org/article/S1525-1578(16)30223-9/fulltext'
};
