import { Component } from '@angular/core';

@Component({
  selector: 'app-error-icon',
  template: `
    <owc-icon class="one-error-validation-icon" name="alarm" family="filled"></owc-icon>
  `,
  styleUrls: ['./app-error-icon.component.scss']
})
export class AppErrorIconComponent {}
