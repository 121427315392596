import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tabs-with-navigation',
  templateUrl: './tabs-with-navigation.component.html',
  styleUrls: ['./tabs-with-navigation.component.scss']
})
export class TabsWithNavigationComponent implements AfterViewInit {
  @ViewChild('tabsContainer') tabsContainer: ElementRef;
  showNextButton = false;

  ngAfterViewInit(): void {
    this.evaluateScroll();
  }

  scroll(direction: 'left' | 'right'): void {
    const scrollAmount =
      this.tabsContainer.nativeElement.getBoundingClientRect().width *
      (direction === 'left' ? -1 : 1);
    const currentScrollLeft = this.tabsContainer.nativeElement.scrollLeft;
    this.tabsContainer.nativeElement.scrollTo({
      left: currentScrollLeft + scrollAmount,
      top: 0,
      behavior: 'smooth'
    });
  }

  evaluateScroll(): void {
    if (!this.tabsContainer) {
      return;
    }

    // Need to use setTimeout to wait for the OWC tabs to update their width
    setTimeout(() => {
      this.showNextButton = this.isElementOverflowing(this.tabsContainer.nativeElement);
    }, 200);
  }

  isElementOverflowing(element: HTMLElement): boolean {
    if (!element) {
      return false;
    }

    return element.scrollWidth > element.clientWidth;
  }
}
