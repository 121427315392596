<ng-container [ngSwitch]="type">
  <span *ngSwitchCase="VariantDisplayFieldType.PROTEIN_FEATURES">
    <div *ngIf="!value.threePrimeGene && !value.fivePrimeGene; else geneFusion">
      <ng-container *ngFor="let proteinFeature of value; last as isLast">
        {{ proteinFeature.label }} (<app-translation
        [translationKey]="proteinFeature.status"
        ></app-translation
        >)<ng-container *ngIf="!isLast">, </ng-container>
      </ng-container>
    </div>
    <ng-template #geneFusion>
      <div *ngIf="value.threePrimeGene && value.threePrimeProteinFeatures?.length > 0" class="protein-features-display">
        <span class="gene-fusion-header">{{value.threePrimeGene}}</span>
        <ng-container *ngFor="let proteinFeature of value.threePrimeProteinFeatures; last as isLast">
          {{ proteinFeature.label }} (<app-translation
            [translationKey]="proteinFeature.status"
          ></app-translation
          >)<ng-container *ngIf="!isLast">, </ng-container>
        </ng-container>
      </div>

      <div *ngIf="value.fivePrimeGene && value.fivePrimeProteinFeatures?.length > 0" class="protein-features-display">
        <span class="gene-fusion-header">{{value.fivePrimeGene}}</span>
        <ng-container *ngFor="let proteinFeature of value.fivePrimeProteinFeatures; last as isLast">
          {{ proteinFeature.label }} (<app-translation
            [translationKey]="proteinFeature.status"
          ></app-translation
          >)<ng-container *ngIf="!isLast">, </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </span>
  <span *ngSwitchCase="VariantDisplayFieldType.VARIANT_CLASS">
    <span i18n="VariantDisplayFieldValue.Notes@@cnv" *ngIf="value === VariantClassValues.CNV"
      >CNV</span
    >
    <span
      i18n="VariantDisplayFieldValue.Notes@@title-rearrangement"
      *ngIf="value === VariantClassValues.REARRANGEMENT"
      >Rearrangement</span
    >
    <span
      *ngIf="value === VariantClassValues.RNA_EXON_VARIANT"
      i18n="VariantDisplayFieldValue.Notes@@rna-exon-variant">
      RNA Exon Variant
    </span>
  </span>
  <span *ngSwitchCase="VariantDisplayFieldType.NUCLEIC_ACID_TYPE">{{ value }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.RATIO">{{ value | localeNumber: '0.00' }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.BREAKPOINT_UNCERTAINTY">
    <span
      i18n="VariantDisplayFieldValue.Notes@@precise"
      *ngIf="value === BreakpointUncertaintyValues.PRECISE"
      >Precise</span
    >
    <span
      i18n="VariantDisplayFieldValue.Notes@@imprecise"
      *ngIf="value === BreakpointUncertaintyValues.IMPRECISE"
      >Imprecise</span
    >
  </span>
  <span *ngSwitchCase="VariantDisplayFieldType.IMPACTED_EXONS">
    {{ value.numImpactedExons }} <ng-container i18n="@@of">of</ng-container>
    {{ value.totalNumberOfExons }}
  </span>
  <span *ngSwitchCase="VariantDisplayFieldType.EXON_RANGE">
    {{ value }}
  </span>
  <span *ngSwitchCase="VariantDisplayFieldType.EXON_JUNCTION">
    {{ value }}
  </span>
  <span *ngSwitchCase="VariantDisplayFieldType.IMPACTED_GENES">{{ value.join(', ') }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.SUPPORTING_READS_PERCENTAGE">{{
    value | localeNumber: '0.00'
  }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.SUPPORTING_READS_COUNT">{{
    value | localeNumber: '0,0'
  }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.SUPPORTING_READ_PAIRS">{{
    value | localeNumber: '0,0'
  }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.TOTAL_READ_PAIRS">{{
    value | localeNumber: '0,0'
  }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.FUSION_SUPPORTING_READS">
    <ng-container *ngIf="value; else emptyFieldValue">{{ value | localeNumber: '0,0' }}</ng-container>
  </span>
  <ng-container *ngSwitchCase="VariantDisplayFieldType.DIRECTIONALITY">
    <span
      i18n="VariantDisplayFieldValue.Notes@@known"
      *ngIf="value === DirectionalityEnumValues.KNOWN"
    >Known</span>
    <span
      i18n="VariantDisplayFieldValue.Notes@@unknown"
      *ngIf="value === DirectionalityEnumValues.UNKNOWN"
    >Unknown</span>
  </ng-container>
  <span *ngSwitchCase="VariantDisplayFieldType.REFERENCE_READS_FOR_FIVE_PRIME_GENE_AND_THREE_PRIME_GENE">
  {{ value }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.ALLELE_FRACTION_PERCENTAGE"
    >{{ value | localeNumber: '0,0.[000000]' }}%</span
  >
  <span *ngSwitchCase="VariantDisplayFieldType.COPY_NUMBER">{{
    value | localeNumber: '0,0.00'
  }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.VENDOR_FOLD_CHANGE">{{
    value | localeNumber: '0,0.000000' : true
  }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.QUALITY_SCORE">{{
    value | localeNumber: '0,0.00'
  }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.P_VALUE">{{
    value | localeNumber: '0.000000e+0'
  }}</span>
  <span *ngSwitchCase="VariantDisplayFieldType.QUALITY_ENUM">
    <span
      i18n="VariantDisplayFieldValue.Notes@@title-high-confidence"
      *ngIf="value === QualityEnumValues.HIGH_CONFIDENCE_CNV_CALL"
      >High Confidence</span
    >
    <span
      i18n="VariantDisplayFieldValue.Notes@@title-low-confidence"
      *ngIf="value === QualityEnumValues.LOW_CONFIDENCE_CNV_CALL"
      >Low Confidence</span
    >
  </span>
  <ng-container *ngSwitchCase="VariantDisplayFieldType.EQUIVOCAL">
    <span
      i18n="VariantDisplayFieldValue.Notes@@true"
      *ngIf="value === EquivocalEnumValues.TRUE_EQUIVOCAL"
      >True</span
    >
    <span
      i18n="VariantDisplayFieldValue.Notes@@false"
      *ngIf="value === EquivocalEnumValues.FALSE_EQUIVOCAL"
      >False</span
    >
  </ng-container>
  <ng-container *ngSwitchCase="VariantDisplayFieldType.READING_FRAME">
    <span
      i18n="VariantDisplayFieldValue.Notes@@in-frame"
      *ngIf="value === ReadingFrameEnumValues.IN_FRAME"
      >In frame</span
    >
    <span
      i18n="VariantDisplayFieldValue.Notes@@out-of-frame"
      *ngIf="value === ReadingFrameEnumValues.OUT_OF_FRAME"
      >Out of frame</span
    >
    <span
      i18n="VariantDisplayFieldValue.Notes@@not-determined"
      *ngIf="value === ReadingFrameEnumValues.NOT_DETERMINED"
      >Not determined</span
    >
  </ng-container>
  <ng-container *ngSwitchCase="VariantDisplayFieldType.VENDOR_ANNOTATED_IN_FRAME">
    <span
      i18n="VariantDisplayFieldValue.Notes@@true"
      *ngIf="value === InFrameEnumValues.IN_FRAME_TRUE"
      >True</span
    >
    <span
      i18n="VariantDisplayFieldValue.Notes@@false"
      *ngIf="value === InFrameEnumValues.IN_FRAME_FALSE"
      >False</span
    >
    <span
      i18n="VariantDisplayFieldValue.Notes@@unknown"
      *ngIf="value === InFrameEnumValues.IN_FRAME_UNKNOWN"
      >Unknown</span
    >
  </ng-container>

  <ng-container *ngSwitchCase="VariantDisplayFieldType.CUSTOM_GENOMIC_RANGE">
    <app-variant-last-name
      *ngIf="value.consequence"
      [last]="value.consequence"
      [truncateLength]="15"
    ></app-variant-last-name>
    <app-structural-variant-title
      class="word-wrap"
      [gene]="value.gene"
      [chromosome]="value.chr"
      [breakpointStart]="value.startPos"
      [breakpointEnd]="value.endPos"
    >
    </app-structural-variant-title>
  </ng-container>
  <ng-container *ngSwitchCase="VariantDisplayFieldType.IN_STRAND">
    <span
      i18n="VariantDisplayFieldValue.Notes@@yes"
      *ngIf="value"
      >Yes</span
    >
    <span
      i18n="VariantDisplayFieldValue.Notes@@no"
      *ngIf="!value"
      >No</span
    >
  </ng-container>
  <span *ngSwitchCase="VariantDisplayFieldType.REFERENCE_READS_TRANSCRIPT">
    {{value | localeNumber: '0,0'}}
  </span>
</ng-container>
<ng-template #emptyFieldValue><span>-</span></ng-template>
