import { Link, Links } from './authUser';
import { BaseEntity } from './baseEntity';
import { Coordinate } from './coordinate';
import { ApiPaginatedResponse } from './pagination';

class BaseBiomarker extends BaseEntity {
  name: string;
}

export class Biomarker extends BaseBiomarker {
  id: number;
  coordinates: Coordinate[];

  constructor(jsonData: any) {
    super(jsonData);
    this.coordinates = jsonData.coordinates
      ? jsonData.coordinates.map((coordinate) => new Coordinate(coordinate))
      : [];
  }
}

export class PertinentNegativeBiomarker extends BaseBiomarker {
  id: string;
  pertinentNegative?: boolean;
}

export interface PaginatedBiomarkerData {
  _embedded: {
    biomarkerDtoWithLinks: {
      annotatedVariantDto: PertinentNegativeBiomarker[];
      _links: Link[] | Links[];
    };
  };
  page: ApiPaginatedResponse;
}
