import {
  interval,
  combineLatest,
  fromEvent,
  merge,
  Subject,
  BehaviorSubject,
  throttle
} from 'rxjs';
import { takeUntil, filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { FileUploadService } from './file-upload.service';
import { AuthService } from './auth.service';

export const LOGOUT_TIME = environment.logoutTimeMinutes;

const MOUSE_EVENT_THROTTLE_MS = 1000;
const RESET_MESSAGE = 'reset-timer';
const RESET_CHANNEL_NAME = 'nmp-autologout-reset-timer';

@Injectable()
export class AutoLogoutService {
  private _minutesRemaining: BehaviorSubject<number> = new BehaviorSubject(null);
  private isTicking = false;
  private _stop = new Subject<void>();
  private resetTimeChannel: BroadcastChannel;

  constructor(private authService: AuthService, private fileUploadService: FileUploadService) {}

  start(): void {
    this.initializeChannelListener();
    this.initializeTimer();
    this.listenForEvents();
    this.listenForExpiredTime();
  }

  private initializeChannelListener(): void {
    this.resetTimeChannel = new BroadcastChannel(RESET_CHANNEL_NAME);
    this.resetTimeChannel.onmessage = (event) => {
      if (event.data === RESET_MESSAGE) {
        this.resetLocalTimer();
      }
    };
  }

  // starts timer if a user is logged in and the logoutTime has been loaded through the config endpoint
  private initializeTimer(): void {
    combineLatest([this.authService.currentUser, this.fileUploadService.uploadPending])
      .pipe(map(([currentUser, uploadPending]) => currentUser && !uploadPending))
      .subscribe((enableTicking) => {
        this.resetTimerAndBroadcast();
        this.isTicking = enableTicking;
      });

    const everyMinute = 1000 * 60;

    interval(everyMinute)
      .pipe(
        filter(() => this.isTicking),
        takeUntil(this._stop)
      )
      .subscribe(() => this._minutesRemaining.next(this._minutesRemaining.getValue() - 1));
  }

  private listenForEvents(): void {
    merge(
      fromEvent(document, 'click'),
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keypress')
    )
      .pipe(
        filter(() => this.isTicking),
        throttle(() => interval(MOUSE_EVENT_THROTTLE_MS)),
        takeUntil(this._stop)
      )
      .subscribe(() => this.resetTimerAndBroadcast());
  }

  private listenForExpiredTime(): void {
    this._minutesRemaining
      .pipe(
        filter((remainingTime) => remainingTime === 0),
        takeUntil(this._stop)
      )
      .subscribe(() => {
        this.authService.logoutCurrentUser();
        this.stop();
      });
  }

  resetTimerAndBroadcast(): void {
    this.resetLocalTimer();
    this.broadcastResetTimer();
  }

  private resetLocalTimer(): void {
    this._minutesRemaining.next(LOGOUT_TIME);
  }

  private broadcastResetTimer() {
    this.resetTimeChannel.postMessage(RESET_MESSAGE);
  }

  stop(): void {
    this._stop.next();
  }
}
