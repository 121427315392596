import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpXsrfTokenExtractor
} from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthService } from './services/auth.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.proxyApiUrl) {
      req = req.clone({ url: req.url.replace(environment.proxyApiUrl, window.location.origin) });
    }

    if (req.method === 'GET' || req.method === 'HEAD') {
      return next.handle(req);
    }

    const token = this.tokenExtractor.getToken();

    if (token) {
      req = req.clone({
        headers: req.headers.set('X-XSRF-TOKEN', token)
      });
    }

    return next.handle(req).pipe(
      catchError((response) => {
        if (response instanceof HttpErrorResponse && response.status === 401) {
          this.authService.logoutCurrentUser();
        }
        return throwError(() => response);
      })
    );
  }
}
