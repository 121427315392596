<div>
  <div mat-dialog-title oneDialogTitle>
    <span class="mat-title">
      <app-default-biomarker-filter-text *ngIf="type; else filtersText" [biomarkerType]="type"></app-default-biomarker-filter-text>
      <ng-template #filtersText i18n="FilterPopup.Notes@@filters">Filters</ng-template>
    </span>
  </div>
  <div mat-dialog-content oneDialogContent>
    <app-variant-filter
      [manualMode]="data.manualMode"
      [editMode]="data.editMode"
      [allowApplyFiltersForTierIAndII]="data.allowApplyFiltersForTierIAndII"
      [initialIgnoreFiltersForTierIAndII]="data.initialIgnoreFiltersForTierIAndII"
      [isDefaultFilter]="data.isDefaultFilter"
      [displayAsPopup]="true"
      [canEditFilter]="data.canEditVariantFilter"
      [filtersList]="data.filterList"
      (close)="closeDialog()" (outputData)="outputData($event)">
    </app-variant-filter>
  </div>
</div>
