import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Mate } from 'app/model/entities/mate';

@Component({
  selector: 'app-breakpoint-popup',
  templateUrl: './breakpoint-popup.component.html',
  styleUrls: ['./breakpoint-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreakpointPopupComponent {
  data: Mate[];
  displayedColumns = [
    'breakPoints',
    'supportingReadsCount',
    'supportingReadsFraction',
    'qualityScore'
  ];
  visible: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  close(): void {
    this.visible = false;
  }

  open(data: Mate[]): void {
    this.data = data;
    this.visible = true;
    this.changeDetectorRef.detectChanges();
  }
}
