<!--
  TODO: Phase 4: Remove old implementation once OWC migration is finished for all pages.
  Reference: https://rds-csi.atlassian.net/browse/ISTN-6656
-->
<div class="overlay" *ngIf="(loaderService.loadingStatus$ | async)">
  <mat-spinner mode="indeterminate" strokeWidth="5" diameter="48"></mat-spinner>
</div>

<!--
  TODO: if the  overflow="hidden"  style rule **reliably** vanishes from <body/>
  when the  owcProgressSpinner$  emits false, stop disabling scroll lock.
-->
<owc-backdrop *ngIf="(loaderService.owcProgressSpinner$ | async)" disableScrollLock>
  <owc-progress-spinner [diameter]="50"></owc-progress-spinner>
</owc-backdrop>
