export enum TmbMsiThresholdErrorType {
  TMB = 'TMB',
  MSI = 'MSI',
  TMB_MSI = 'TMB & MSI'
}

export enum BiomarkerThresholdTypes {
  TMB = 'TMB',
  MSI = 'MSI',
  HRD = 'HRD',
  MSI_JSD = 'MSI_JSD'
}

export enum TmbMsiThresholdVersion {
  V1 = 'tmb_msi_threshold_capstone_ruo_v1',
  V2 = 'tmb_msi_threshold_capstone_ruo_v2',
  V3 = 'tmb_msi_hrd_threshold_capstone_ruo_v3',
  V3_ASSAY_SPECIFIC = 'ASSAY_SPECIFIC_OTHER_BIOMARKER_THRESHOLDS'
}
