<div class="navigation-container">
  <div class="navigation-container-pager-btn">
    <owc-icon-button *ngIf="tabsContainer?.scrollLeft > 0" flat (click)="scroll('left')">
      <owc-icon name="chevron_left" family="outlined"></owc-icon>
    </owc-icon-button>
  </div>
  <div #tabsContainer class="tabs-container" (scroll)="evaluateScroll()">
    <div class="tabs-content">
      <!-- The child content, mostly tabs, goes here -->
      <ng-content></ng-content>
    </div>
  </div>
  <div class="navigation-container-pager-btn">
    <owc-icon-button *ngIf="showNextButton" flat (click)="scroll('right')">
      <owc-icon name="chevron_right" family="outlined"></owc-icon>
    </owc-icon-button>
  </div>
</div>
