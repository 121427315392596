export enum VARIANT_DISPLAY_FIELD_TYPE {
  SUPPORTING_READS_PERCENTAGE = 'SUPPORTING_READS_PERCENTAGE',
  SUPPORTING_READS_COUNT = 'SUPPORTING_READS_COUNT',
  COPY_NUMBER = 'COPY_NUMBER',
  VENDOR_FOLD_CHANGE = 'VENDOR_FOLD_CHANGE',
  QUALITY_SCORE = 'QUALITY_SCORE',
  P_VALUE = 'P_VALUE',
  QUALITY_ENUM = 'QUALITY_ENUM',
  EQUIVOCAL = 'EQUIVOCAL',
  VENDOR_ANNOTATED_IN_FRAME = 'VENDOR_ANNOTATED_IN_FRAME',
  SUPPORTING_READ_PAIRS = 'SUPPORTING_READ_PAIRS',
  TOTAL_READ_PAIRS = 'TOTAL_READ_PAIRS',
  FUSION_SUPPORTING_READS = 'FUSION_SUPPORTING_READS',
  REFERENCE_READS_FOR_FIVE_PRIME_GENE_AND_THREE_PRIME_GENE = 'REFERENCE_READS_FOR_FIVE_PRIME_GENE_AND_THREE_PRIME_GENE',
  DIRECTIONALITY = 'DIRECTIONALITY',
  ALLELE_FRACTION_PERCENTAGE = 'ALLELE_FRACTION_PERCENTAGE',
  VARIANT_CLASS = 'VARIANT_CLASS',
  RATIO = 'RATIO',
  IMPACTED_GENES = 'IMPACTED_GENES',
  BREAKPOINT_UNCERTAINTY = 'BREAKPOINT_UNCERTAINTY',
  IMPACTED_EXONS = 'IMPACTED_EXONS',
  EXON_RANGE = 'EXON_RANGE',
  EXON_JUNCTION = 'EXON_JUNCTION',
  NUCLEIC_ACID_TYPE = 'NUCLEIC_ACID_TYPE',
  READING_FRAME = 'READING_FRAME',
  PROTEIN_FEATURES = 'PROTEIN_FEATURES',
  TRANSCRIPT_ID = 'TRANSCRIPT_ID',
  CUSTOM_GENOMIC_RANGE = 'CUSTOM_GENOMIC_RANGE',
  IN_STRAND = 'IN_STRAND',
  REFERENCE_READS_TRANSCRIPT = 'REFERENCE_READS_TRANSCRIPT'
}

export enum QUALITY_ENUM_VALUE {
  HIGH_CONFIDENCE_CNV_CALL = 'HIGH_CONFIDENCE_CNV_CALL',
  LOW_CONFIDENCE_CNV_CALL = 'LOW_CONFIDENCE_CNV_CALL'
}

export enum EQUIVOCAL_ENUM_VALUE {
  TRUE_EQUIVOCAL = 'TRUE_EQUIVOCAL',
  FALSE_EQUIVOCAL = 'FALSE_EQUIVOCAL'
}

export enum DIRECTIONALITY_ENUM_VALUE {
  KNOWN = 'KNOWN',
  UNKNOWN = 'UNKNOWN'
}

export enum IN_FRAME_ENUM_VALUE {
  IN_FRAME_TRUE = 'IN_FRAME_TRUE',
  IN_FRAME_FALSE = 'IN_FRAME_FALSE',
  IN_FRAME_UNKNOWN = 'IN_FRAME_UNKNOWN'
}

export enum READING_FRAME_ENUM_VALUE {
  IN_FRAME = 'IN_FRAME',
  OUT_OF_FRAME = 'OUT_OF_FRAME',
  NOT_DETERMINED = 'NOT_DETERMINED'
}

export enum BREAKPOINT_UNCERTAINTY_VALUE {
  PRECISE = 'PRECISE',
  IMPRECISE = 'IMPRECISE'
}

export enum VARIANT_CLASS_VALUE {
  CNV = 'CNV',
  REARRANGEMENT = 'REARRANGEMENT', // For DNA gene fusions (Capstone)
  RNA_EXON_VARIANT = 'RNA_EXON_VARIANT' // For RNA gene fusions (TSO500) and splice variants (TSO500)
}

export enum PROTEIN_FEATURES_STATUS {
  DISRUPTED = 'DISRUPTED',
  INTACT = 'INTACT'
}
