import { CaseLinkKeys } from 'app/model/valueObjects/caseLinksKeys';
import { combineLatest, BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delay, repeatWhen, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from 'app/services/auth.service';
import { CaseService } from 'app/services/case.service';
import { VariantService } from 'app/services/variant.service';
import { TrialService } from 'app/services/trial.service';
import { CaseStatus } from 'app/model/valueObjects/caseStatus';
import { Case } from 'app/model/entities/case';

// Enum to help display different states in UI (This is not mapped with any transition from API status)
export enum ApprovalStates {
  default = 'IN_ANALYSIS',
  sentForApproval = 'SENT_FOR_APPROVAL',
  approveOrReject = 'APPROVE_REJECT',
  signed = 'SIGNED'
}

export enum ApprovalAction {
  submitForApproval = 'submitForApproval',
  approve = 'approve',
  reject = 'reject'
}

@Injectable()
export class ApprovalService {
  public reportResultsLocked: Observable<boolean>;
  public reportMetaDataLocked: Observable<boolean>;
  public approvalState: Observable<ApprovalStates>;
  private readonly _pollInterval: number = 3000;
  private _transitionInProgress = new BehaviorSubject<boolean>(false);
  private _caseStatus = new BehaviorSubject<CaseStatus>(null);
  private caseStatus = this._caseStatus.asObservable();
  private _transitionErrorCode = new BehaviorSubject<number>(null);
  private _pollSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private caseService: CaseService,
    private variantService: VariantService,
    private trialService: TrialService
  ) {
    this.approvalState = combineLatest([
      this.authService.currentUser,
      this.caseStatus,
      this.caseService.currentCase
    ]).pipe(
      map(([currentUser, caseStatusPolling, currentCase]) => {
        if (!currentUser) return ApprovalStates.default;

        const caseStatus = caseStatusPolling || (currentCase as Case).status;

        if (caseStatus === CaseStatus.SIGNED) {
          return ApprovalStates.signed;
        } else if (caseStatus === CaseStatus.AWAITING_APPROVAL) {
          return this.caseService.hasPermission(CaseLinkKeys.APPROVE_CASE)
            ? ApprovalStates.approveOrReject
            : ApprovalStates.sentForApproval;
        } else {
          return ApprovalStates.default;
        }
      })
    );
  }
  get caseStatus$(): Observable<CaseStatus> {
    return this.caseStatus;
  }

  get transitionInProgress(): Observable<boolean> {
    return this._transitionInProgress.asObservable();
  }

  get transitionErrorCode(): Observable<number> {
    return this._transitionErrorCode.asObservable();
  }

  get revisionData() {
    return Object.assign(
      this.caseService.revisionData,
      this.variantService.revisionData,
      this.trialService.revisionData
    );
  }

  submitForApproval(caseId: string) {
    this._transitionInProgress.next(true);

    return this.http
      .put(
        this.caseService.getCurrentCaseLink(CaseLinkKeys.SUBMIT_CASE_FOR_APPROVAL),
        this.revisionData
      )
      .subscribe({
        next: (result) => this.actionSuccess(result, caseId),
        error: (error) => this.actionFailure(error)
      });
  }

  pollCaseStatus(url: string, caseId: string) {
    this._pollSubscription = this.http
      .get(url)
      .pipe(repeatWhen((completed) => completed.pipe(delay(this._pollInterval))))
      .subscribe((res: any) => {
        this._caseStatus.next(res.status);
        if (res.transitionInProgress) {
          return;
        }

        // If transition is completed, then stop polling
        this.stopPollingAndReset();

        if (this.caseService.currentCaseStatus === res.status) {
          this._transitionErrorCode.next(600); // state did not change -> show error
          return;
        }

        this.variantService.clearVariantListETag(); // This will force to reload variant-list for updated permissions
        this.caseService.loadCase(caseId, false);
      });
  }

  stopPollingAndReset(resetCaseData?: boolean) {
    if (this._pollSubscription) {
      this._pollSubscription.unsubscribe();
    }
    this.resetData(resetCaseData);
  }

  private actionSuccess(result: any, caseId: string) {
    // Start polling for case status
    if (result.transitionInProgress) {
      this.pollCaseStatus(result['_links'].checkStatus.href, caseId);
    }
  }

  private actionFailure(error: any) {
    this._transitionInProgress.next(false);
    this._transitionErrorCode.next(error.status);
  }

  private resetData(resetCaseData?: boolean) {
    this._transitionInProgress.next(false);
    this._transitionErrorCode.next(null);
    this._caseStatus.next(null);
    if (resetCaseData) {
      this.caseService.resetCurrentCase();
    }
  }

  approveCase(caseId: string) {
    this._transitionInProgress.next(true);

    return this.http
      .put(this.caseService.getCurrentCaseLink(CaseLinkKeys.APPROVE_CASE), this.revisionData)
      .subscribe({
        next: (result) => this.actionSuccess(result, caseId),
        error: (error) => this.actionFailure(error)
      });
  }

  rejectCase(caseId: string) {
    this._transitionInProgress.next(true);

    return this.http
      .put(this.caseService.getCurrentCaseLink(CaseLinkKeys.REJECT_CASE), this.revisionData)
      .subscribe({
        next: (result) => this.actionSuccess(result, caseId),
        error: (error) => this.actionFailure(error)
      });
  }
}
