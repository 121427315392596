import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnnotatedVariant } from 'app/model/entities/annotatedVariant';
import { VARIANT_DISPLAY_FIELD_TYPE } from 'app/model/valueObjects/variantDisplayFieldType';

@Component({
  selector: 'app-variant-display-field-type',
  templateUrl: './variant-display-field-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariantDisplayFieldTypeComponent {
  VariantDisplayFieldType = VARIANT_DISPLAY_FIELD_TYPE;
  @Input() type: string;
  @Input() compact = true;
  @Input() annotatedVariant: AnnotatedVariant;
}
