import { Component, Input } from '@angular/core';
import { random as _random } from 'lodash';

@Component({
  selector: 'app-variant-last-name',
  templateUrl: './variant-last-name.html'
})
export class VariantLastNameComponent {
  @Input() last: string;
  @Input() truncateLength = 41;

  // This is needed to set the id of the tooltip element to a random string.
  dateNow = _random(0, Number.MAX_SAFE_INTEGER).toString();

  constructor() {}
}
