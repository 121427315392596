<span *ngFor="let nameElement of name; last as isLastElement" class="word-wrap">
  <span [class.bold-text]="boldFirstName" *ngIf="displayExpandedBioMarker(nameElement.first); else regularFirst">
    <ng-container *ngIf="nameElement.first === 'MSS'">Microsatellite Stable (MSS)</ng-container>
    <ng-container *ngIf="nameElement.first === 'MSI'">Microsatellite Instability (MSI) -</ng-container>
    <ng-container *ngIf="nameElement.first === 'TMB'">Tumor Mutation Burden (TMB) -</ng-container>
    <ng-container *ngIf="nameElement.first === 'HRDsig'">Homologous Recombination Deficiency Signature (HRDsig) -</ng-container>
    <ng-container *ngIf="nameElement.first === 'Genomic LOH'">Loss of Heterozygosity (LOH) -</ng-container>
  </span>
  <ng-template #regularFirst>
    <span [class.bold-text]="boldFirstName">{{nameElement.first}}</span>
  </ng-template>
  <span *ngIf="nameElement.last" [class.expand-bio]="displayExpandedBioMarker(nameElement.first)">
    <span *ngIf="nameElement.first" ngPreserveWhitespaces> </span>
    <app-variant-last-name [last]="nameElement.last" [truncateLength]="truncateLength"></app-variant-last-name>
  </span>
  <span *ngIf="showAlias && nameElement.alias" ngPreserveWhitespaces [attr.title]="nameElement.alias"> ({{nameElement.alias}})</span>
  <span *ngIf="!isLastElement">
    <span *ngIf="multipleLines" ngPreserveWhitespaces> + </span>
    <span *ngIf="!multipleLines" ngPreserveWhitespaces>, </span>
  </span>
  <br *ngIf="multipleLines"/>
</span>
